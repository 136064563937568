import { Fade, styled } from '@mui/material'
import { FulfillmentStatus, Order, OrderDefectType, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import { FC } from 'react'
import { TableCell, TableRow } from '../Table'
import { renderOrderTableCellByColumnId } from './helpers/renderOrderTableCellByColumnId'
import { OrderTableColumn } from './types/OrderTableColumn'
import { OrderTableColumnId } from './types/OrderTableColumnId'

interface Props {
  order: Order
  columns: OrderTableColumn[]
  selected?: boolean
  onClick?: (order: Order) => void
}

export const OrderTableRow: FC<Props> = ({ order, columns, selected, onClick }) => {
  if (!order) return null
  const durationCols = [
    OrderTableColumnId.WAITING_TIME,
    OrderTableColumnId.PICKING_TIME,
    OrderTableColumnId.DELIVERY_TIME,
    OrderTableColumnId.WAITING_AT_PICKUP,
    OrderTableColumnId.RIDING_TIME,
    OrderTableColumnId.DELIVERY_TIME,
  ]
  const textStatusCols = [
    OrderTableColumnId.FULFILLMENT_STATUS,
    OrderTableColumnId.PICKING_STATUS,
    OrderTableColumnId.DELIVERY_STATUS,
  ]
  const getWidth = (id: OrderTableColumnId) => {
    if (durationCols.includes(id)) {
      return '100px'
    }
    if (textStatusCols.includes(id)) {
      return '120px'
    }
    if (id === OrderTableColumnId.HANDOVER_AT_PICKUP) {
      return '150px'
    }
    if (id === OrderTableColumnId.RIDER_V2) {
      return '300px'
    }
    if (id === OrderTableColumnId.PICKER) {
      return '200px'
    }
    if (id === OrderTableColumnId.DELIVERY_PROMISE) {
      return '140px'
    }
    if (id === OrderTableColumnId.ID) {
      return '185px'
    }
    return 'auto'
  }

  return (
    <Fade in={true} timeout={300} unmountOnExit mountOnEnter>
      <StyledTableRow
        key={order.id}
        order={order}
        hover={!!onClick}
        selected={selected}
        onClick={() => onClick?.(order)}
      >
        {columns.map(({ id, align }) => (
          <TableCell key={id} scope="row" align={align} sx={{ width: getWidth(id) }}>
            {renderOrderTableCellByColumnId(id, order)}
          </TableCell>
        ))}
      </StyledTableRow>
    </Fade>
  )
}

const StyledTableRow = styled(TableRow)<{ order: Order }>(({ theme, order }) => {
  const fulfillmentStatus = order.fulfillmentStatus
  const pickingStatus = order.pickingStatus
  const deliveryStatus = order.deliveryStatus
  const hasOOSDefect = !!order.orderDefects?.find(({ type }) => type === OrderDefectType.ITEM_OOS)
  const isRiderWaitingAtPickup =
    pickingStatus === PickingStatus.PACKED &&
    deliveryStatus === TaskState.ASSIGNED &&
    order.deliveryRiderArrivedForPickupAt

  return {
    height: '65px',
    ':hover:not(.MuiTableRow-hover)': {
      cursor: 'default',
    },
    ...(deliveryStatus === TaskState.UNASSIGNED && {
      backgroundColor: theme.status.danger.primary,
      '@keyframes bg-new': {
        '0%': {
          opacity: 0,
        },
        '25%': {
          opacity: 0.25,
        },
        '50%': {
          opacity: 0.5,
        },
        '75%': {
          opacity: 0.75,
        },
        '100%': {
          opacity: 1,
        },
      },
      animation: 'bg-new 1s ease-in-out',

      '&.MuiTableRow-hover:hover': {
        backgroundColor: '#8c1b30',
      },

      '&.Mui-selected': {
        backgroundColor: theme.status.danger.secondary,
      },
    }),

    ...(isRiderWaitingAtPickup && {
      backgroundColor: 'rgba(54,109,90, 1)',
      ':hover': {
        backgroundColor: 'rgb(44,99,80, 1)',
      },

      '&.MuiTableRow-hover:hover': {
        backgroundColor: 'rgb(44,99,80, 1)',
      },

      '&.Mui-selected': {
        backgroundColor: 'rgb(75,162,118, 1)',
      },
      '@keyframes bg-ready': {
        '0%': {
          opacity: 0,
        },
        '25%': {
          opacity: 0.25,
        },
        '50%': {
          opacity: 0.5,
        },
        '75%': {
          opacity: 0.75,
        },
        '100%': {
          opacity: 1,
        },
      },
      animation: 'bg-ready 1s ease-in-out',
    }),

    ...(deliveryStatus === TaskState.RETURNING && {
      backgroundColor: 'rgba(2, 186, 255, 0.2)',

      '&.MuiTableRow-hover:hover': {
        backgroundColor: 'rgba(2, 186, 255, 0.3)',
      },

      '&.Mui-selected': {
        backgroundColor: 'rgba(2, 186, 255, 0.5)',
      },
    }),

    ...([FulfillmentStatus.CANCELLED, FulfillmentStatus.FULFILLED].includes(
      fulfillmentStatus ?? ('' as FulfillmentStatus)
    ) && {
      opacity: 0.6,
      backgroundColor: 'initial',

      '&.MuiTableRow-hover:hover': {
        backgroundColor: 'initial',
      },

      '&.Mui-selected': {
        backgroundColor: theme.palette.background.default,
      },
    }),

    ...(hasOOSDefect && {
      backgroundColor: 'rgba(200, 97, 2, 0.3)',
      opacity: 1,

      '&.MuiTableRow-hover:hover': {
        backgroundColor: 'rgba(153, 77, 6, 0.2)',
      },

      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 137, 28, 0.2)',
      },
    }),
  }
})
