export enum OrderTableColumnId {
  ID = 'id',
  CREATED_AT = 'createdAt',
  FULFILLMENT_STATUS = 'fulfillmentStatus',
  DELIVERY_STATUS = 'deliveryStatus',
  PICKING_STATUS = 'pickingStatus',
  PICKER = 'picker',
  RIDER = 'rider',
  RIDER_V2 = 'riderV2',
  RIDER_STATUS = 'riderStatus',
  WAITING_TIME = 'waitingTime',
  PICKING_TIME = 'pickingTime',
  PACKING_TIME = 'packingTime',
  RACK_TIME = 'rackTime',
  WAITING_AT_PICKUP = 'waitingAtPickup',
  HANDOVER_AT_PICKUP = 'handoverAtPickup',
  RIDING_TIME = 'ridingTime',
  DELIVERY_TIME = 'deliveryTime',
  DELIVERY_PROMISE = 'deliveryPromise',
  ORDERED_ITEM_TYPES = 'orderedItemTypes',
  DELIVERY_FEATURES = 'deliveryFeatures',
  PACKAGE_COUNT = 'packageCount',
}
