import { OrderTableColumn } from '../types/OrderTableColumn'
import { OrderTableColumnId } from '../types/OrderTableColumnId'

export const DEFAULT_ORDER_TABLE_COLUMNS: OrderTableColumn[] = [
  {
    id: OrderTableColumnId.ID,
    label: 'Order #',
    align: 'right',
  },
  {
    id: OrderTableColumnId.CREATED_AT,
    label: 'Created',
    align: 'center',
  },
  {
    id: OrderTableColumnId.FULFILLMENT_STATUS,
    label: 'Fulfillment',
    align: 'center',
  },
  {
    id: OrderTableColumnId.DELIVERY_STATUS,
    label: 'Delivery',
    align: 'center',
  },
  {
    id: OrderTableColumnId.PICKING_STATUS,
    label: 'Picking',
    align: 'center',
  },
  {
    id: OrderTableColumnId.PICKER,
    label: 'Picker',
    align: 'left',
  },
  {
    id: OrderTableColumnId.PACKAGE_COUNT,
    label: 'Packages',
    align: 'left',
  },
  {
    id: OrderTableColumnId.RIDER_V2,
    label: 'Rider',
    align: 'left',
  },
  {
    id: OrderTableColumnId.WAITING_TIME,
    label: 'Waiting',
    align: 'center',
  },
  {
    id: OrderTableColumnId.PICKING_TIME,
    label: 'Picking',
    align: 'center',
  },
  {
    id: OrderTableColumnId.PACKING_TIME,
    label: 'Packing',
    align: 'center',
  },
  {
    id: OrderTableColumnId.RACK_TIME,
    label: 'Racking',
    align: 'center',
  },
  {
    id: OrderTableColumnId.RIDING_TIME,
    label: 'Riding',
    align: 'center',
  },
  {
    id: OrderTableColumnId.DELIVERY_TIME,
    label: 'End-to-End',
    align: 'center',
  },
  {
    id: OrderTableColumnId.DELIVERY_PROMISE,
    label: 'Promised',
    align: 'center',
  },
]
